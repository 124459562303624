import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { window } from "browser-monads";
import Hamburger from "hamburger-react";
import { useTransition } from "react-spring";

import { TransitionMixin, media } from "../helpers";
import Wrapper from "../elements/wrapper";
import MobileMenu from "./mobile-menu";

const HeaderContainer = styled.div`
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
  padding: 15px 0;
  background: -moz-linear-gradient(top, #25211e 0, rgba(37, 33, 30, 0) 100%);
  background: -webkit-linear-gradient(top, #25211e 0, rgba(37, 33, 30, 0) 100%);
  background: linear-gradient(to bottom, #25211e 0, rgba(37, 33, 30, 0) 100%);
  ${TransitionMixin(".25s")}

  &.sticky {
    background: #fff;
    border-bottom: 1px solid #ccc;

    .header-wrapper {
      .logo-container {
        a {
          color: #4a4a4a;
        }
      }
      .link-container {
        .hamburger-container {
          .hamburger-react {
            > div {
              background: #4a4a4a !important;
            }
          }
        }
        .desktop-nav {
          ul {
            li {
              a {
                color: #4a4a4a;
              }
            }
          }
        }
      }
    }
  }
  .header-wrapper {
    align-items: center;

    .logo-container {
      flex: 2;
      ${media.medium`flex: 1;`}

      a {
        display: inline-block;
        text-decoration: none;
        color: #fff;

        h1 {
          margin: 0;
          font-size: 1.5rem;
          line-height: 1.2;
          font-family: "Jost", sans-serif;
          ${media.medium`font-size: 1.5rem;`}

          span {
            display: block;
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
              Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
              sans-serif;
            font-weight: 300;
            font-size: 15px;
            margin-top: 5px;
            ${media.medium`font-size: 18px;`}
          }
        }
      }
    }

    .link-container {
      flex: 1;
      text-align: right;

      .hamburger-container {
        ${media.medium`display: none;`}
        .hamburger-react {
          margin-left: auto;
        }
      }

      .desktop-nav {
        text-align: right;
        display: none;
        ${media.medium`display: block;`}
        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            display: inline-block;
            margin-right: 30px;
            margin-bottom: 0px;

            &:last-child {
              margin-right: 0px;
            }

            a {
              color: #fff;
              text-decoration: none;
              text-transform: uppercase;
              letter-spacing: 1px;
              font-weight: bold;
              font-size: 15px;
            }
          }
        }
      }
    }
  }
`;

const Header = ({ contactRef, resourcesRef, aboutRef }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const links = [
    {
      link_text: "About",
      ref: aboutRef,
    },
    {
      link_text: "Resources",
      ref: resourcesRef,
    },
    {
      link_text: "Contact",
      ref: contactRef,
    },
  ];

  const mobileMenuTransitions = useTransition(isMobileMenuOpen, null, {
    from: { opacity: 0, transform: "translate3d(-100%,0 , 0)" },
    enter: { opacity: 1, transform: "translate3d(0%, 0, 0)" },
    leave: { opacity: 0, transform: "translate3d(-100%,0, 0)" },
  });

  function handleScrollToElement(event, ref) {
    event.preventDefault();
    console.log(ref);
    // window.scrollTo(0, ref.current.offsetTop, "smooth");
    window.scrollTo({
      left: 0,
      top: ref.current.offsetTop - 75,
      behavior: "smooth",
    });
  }

  function handleMobileMenuToggle() {
    if (!isMobileMenuOpen) {
      document.querySelector("html").style.overflowY = "hidden";
      document.querySelector("body").style.overflowY = "hidden";
      setIsMobileMenuOpen(true);
      setIsSticky(true);
    } else {
      document.querySelector("html").style.overflowY = "initial";
      document.querySelector("body").style.overflowY = "initial";
      setIsMobileMenuOpen(false);
      if (window.scrollY > 70) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  }

  useEffect(() => {
    if (window.scrollY > 70) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }

    window.addEventListener("scroll", function() {
      if (window.scrollY > 70) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
      return;
    });

    return () => {
      // clean up
      window.removeEventListener("scroll", function() {
        return;
      });
    };
  }, []);

  return (
    <>
      <HeaderContainer className={isSticky ? "sticky" : ""}>
        <Wrapper className="header-wrapper" flex>
          <div className="logo-container">
            <Link>
              <h1>
                John Edward LaMothe, PhD, PC{" "}
                <span>NYS Licensed Psychologist</span>
              </h1>
            </Link>
          </div>
          <div className="link-container">
            <div className="hamburger-container">
              <Hamburger
                toggled={isMobileMenuOpen}
                toggle={() => handleMobileMenuToggle()}
                color="#fff"
              />
            </div>
            <nav className="desktop-nav">
              <ul>
                {links.map((link, index) => {
                  return (
                    <li key={index}>
                      <a
                        href="#"
                        onClick={e => handleScrollToElement(e, link.ref)}
                      >
                        {link.link_text}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </Wrapper>
      </HeaderContainer>
      {mobileMenuTransitions.map(({ item, key, props }) => {
        return (
          item && (
            <MobileMenu
              key={key}
              style={props}
              links={links}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              isMobileMenuOpen={isMobileMenuOpen}
              handleScrollToElement={handleScrollToElement}
            />
          )
        );
      })}
    </>
  );
};

export default Header;
