import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  padding: 15px 0;
  border-top: 1px solid #efefef;
  .inner-wrap {
    max-width: 90vw;
    margin: 0 auto;
    text-align: center;

    p {
      margin: 0;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.3px;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <div className="inner-wrap">
        <p>
          © {new Date().getFullYear()} John Edward LaMothe, PhD, PC. All rights
          reserved.
        </p>
      </div>
    </FooterContainer>
  );
};

export default Footer;
