import React from "react";
import styled from "styled-components";
import { animated } from "react-spring";

import Wrapper from "../elements/wrapper";

const MobileMenuContainer = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  nav {
    padding-top: 22vh;

    ul {
      list-style: none;

      li {
        margin-bottom: 30px;
        a {
          text-decoration: none;
          font-size: 24px;
          color: #4a4a4a;
        }
      }
    }
  }
`;

const MobileMenu = ({ style, links, handleScrollToElement }) => {
  return (
    <MobileMenuContainer style={{ ...style }}>
      <Wrapper>
        <nav className="mobile-menu">
          <ul>
            {links.map((link, index) => {
              return (
                <li key={index}>
                  <a href="#" onClick={e => handleScrollToElement(e, link.ref)}>
                    {link.link_text}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </Wrapper>
    </MobileMenuContainer>
  );
};

export default MobileMenu;
